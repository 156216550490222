import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { TD_DOMAIN } from "../utils/constants";
import { toast } from "react-toastify";
import {
  errorHandler,
  renderErrorToast,
  validateEmail,
} from "../utils/functions";
import useTimer from "../hooks/useTimer";
import { TDIcon } from "../assets/td.icon";

export function Login() {
  const navigate = useNavigate();
  const [loginStep, setLoginStep] = useState(1);
  const [email, setEmailAddress] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendingToken, setIsResendingToken] = useState(false);
  const [seconds, setStartCountdown] = useTimer();

  const handleDoesUserExist = async () => {
    setLoading(true);
    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setLoading(false);
      return renderErrorToast(
        "Invalid Email",
        "Email format is invalid",
        "invalid-email-address-err"
      );
    }
    try {
      await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v4/getPhoneStatus?email=${email}`
      );
      handleSendUserOTP();
    } catch (err) {
      setLoading(false);
      const { message, statusCode = null } = errorHandler(err);
      const errorMessage =
        statusCode === 404
          ? `Account not found. Please register your account`
          : message;
      renderErrorToast("Oops!", errorMessage, "invalid-user-err");
    }
  };

  const handleSendUserOTP = async (props) => {
    if (props?.isResending) setIsResendingToken(true);
    // else setLoading(true);
    setLoading(false);
    setLoginStep(2);
    try {
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        { mode: "email", email, domain: TD_DOMAIN }
      );
      setIsResendingToken(false);
      setStartCountdown(true);
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast("Send Token Error!", message, "err-sending-token");
      setLoading(false);
      console.log("sending user token error", error);
    }
  };

  const handleConfirmOTP = async () => {
    setLoading(true);
    try {
      const {
        data: { res },
      } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/verify-totp`,
        { email, token }
      );
      handleLogin(res);
    } catch (error) {
      renderErrorToast(
        "Invalid OTP",
        " You have entered an incorrect OTP. Please check and try again",
        "err-verify-token"
      );
      setLoading(false);
    }
  };

  const handleLogin = async (res) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v4/loginWithToken`,
        { accessToken: res.accessToken, email }
      );
      localStorage.setItem("userData", JSON.stringify(data));
      // refreshOutlet();
      toast.dismiss();
      navigate("/console");
    } catch (err) {
      console.log("confirming otp error", err);
      const { message } = errorHandler(err);
      renderErrorToast("Login Error!", message, "err-login-user");
      setLoading(false);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (loginStep === 1 && email) {
          handleDoesUserExist();
        }
        if (loginStep === 2 && token) {
          handleConfirmOTP();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, token]);

  return (
    <div className='tdp_register'>
      <div className='container'>
        <div className='row'>
          <div className='col-4'></div>
          <div
            className={`col-4 form-container ${
              loginStep === 2 && "_confirm-register"
            }`}
          >
            <div className='form-section'>
              <div className='form-icon _logo'>
                <TDIcon />
              </div>
              {loginStep === 1 ? (
                <div className='form-itself'>
                  <h3>Sign in to your account</h3>
                  <input
                    className='form-control'
                    placeholder='Email address'
                    type='email'
                    onChange={({ target: { value } }) => setEmailAddress(value)}
                  />
                  <button
                    className=''
                    onClick={handleDoesUserExist}
                    disabled={!email || loading}
                  >
                    {loading ? (
                      <div
                        class='spinner-border text-success app-spinner'
                        role='status'
                      >
                        <span class='sr-only'></span>
                      </div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                  <h6>
                    Don't have an account?{" "}
                    <span onClick={() => navigate("/register")}>Register</span>
                  </h6>
                </div>
              ) : loginStep === 2 ? (
                <div className='form-itself'>
                  <h3>Confirm login</h3>
                  <h5>
                    Enter the verification code we sent to your email address
                  </h5>
                  <div className='v-input__'>
                    <input
                      placeholder='Enter verification code'
                      className='form-control'
                      disabled={resendingToken}
                      maxLength={6}
                      value={token}
                      onChange={({ target: { value } }) => setToken(value)}
                    />
                    <span
                      onClick={() => {
                        if (seconds) return;
                        setToken("");
                        return handleSendUserOTP({ isResending: true });
                      }}
                    >
                      {resendingToken ? (
                        <div
                          class='spinner-border text-success app-spinner input-spinner'
                          role='status'
                        >
                          <span class='sr-only'></span>
                        </div>
                      ) : !seconds ? (
                        "Resend"
                      ) : (
                        `Resend in ${seconds}s`
                      )}
                    </span>
                  </div>
                  <button
                    onClick={handleConfirmOTP}
                    disabled={token.length < 6 || loading}
                  >
                    {loading ? (
                      <div
                        class='spinner-border text-success app-spinner'
                        role='status'
                      >
                        <span class='sr-only'></span>
                      </div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                  <h6>
                    Still haven’t received a code? Check your spam folder, or{" "}
                    <span
                      onClick={() => {
                        setToken("");
                        setLoginStep(1);
                      }}
                    >
                      try a different email.
                    </span>
                  </h6>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col-4'></div>
        </div>
      </div>
    </div>
  );
}
