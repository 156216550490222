import axios from "axios";
import { useEffect, useState } from "react";
import {
  errorHandler,
  LSUserData,
  renderErrorToast,
} from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { ImageWithFallback } from "../../pages/console";

export function SearchModal() {
  const { apiKey } = LSUserData();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchText) return;
    const runSearchFunction = setTimeout(async () => {
      handleSearch();
    }, 2000);
    return () => clearTimeout(runSearchFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const {
        data: { body },
      } = await axios.post(
        `https://dqspuadfb2.execute-api.us-east-1.amazonaws.com/dev/global-search`,
        { searchTerm: searchText, query: { limit: 3 } },
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setResults(body);
      setLoading(false);
    } catch (error) {
      console.log({ error });
      const { message } = errorHandler(error);
      renderErrorToast(
        "Oops! Something went wrong",
        message || "Unable to search at the moment. Please try again later",
        "search-global-err"
      );
      setLoading(false);
    }
  };

  const closeModal = () => document.getElementById("close-btn").click();

  return (
    <div
      class='modal fade search-modal'
      id='searchModal'
      tabindex='-1'
      aria-labelledby='searchModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog'>
        <div class='modal-content'>
          <div class='modal-header'>
            <div className='search-input'>
              <i className='bi bi-search'></i>
              <input
                type='text'
                placeholder='Search'
                onChange={({ target: { value } }) => setSearchText(value)}
              />
            </div>
            <span
              data-bs-dismiss='modal'
              aria-label='Close'
              className='close-btn'
              id='close-btn'
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.375 5.625L5.625 14.375'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M5.625 5.625L14.375 14.375'
                  stroke='#081F24'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </span>
          </div>
          <div class='modal-body'>
            <div className='search-results-container'>
              {loading ? (
                <h2>Searching...</h2>
              ) : (
                <>
                  <div className='search-results'>
                    <h5>Orders</h5>
                    {results[0]?.orders?.map((el) => (
                      <p
                        onClick={() => {
                          closeModal();
                          navigate(`/console/order/${el._id}`);
                        }}
                      >
                        {el.paymentReference}
                      </p>
                    ))}
                  </div>
                  <div className='search-results'>
                    <h5>Draft Orders</h5>
                    {results[1]?.draftorders?.map((el) => (
                      <p
                        onClick={() => {
                          closeModal();
                          navigate(`/console/order/${el._id}`, {
                            state: { isDraft: true, draftOrder: el },
                          });
                        }}
                      >
                        {el.draftOrderNumber}
                      </p>
                    ))}
                  </div>
                  <div className='search-results'>
                    <h5>Products</h5>
                    {results[2]?.areabasketvariants?.map((el) => (
                      <div className='product'>
                        <ImageWithFallback
                          src={`https://pimg.tradedepot.co/xs/${el.variantId}.png`}
                          fallback={`https://td-dev-img.s3.amazonaws.com/xs/${el.variantId}.png`}
                          alt={el.name}
                        />
                        <p>{el.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className='search-results'>
                    <h5>Invoices</h5>
                    {results[3]?.invoices?.map((el) => (
                      <p
                        onClick={() => {
                          closeModal();
                          navigate(`/console/order/${el._id}`);
                        }}
                      >
                        {el?.name}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div class='modal-footer'></div> */}
        </div>
      </div>
    </div>
  );
}
