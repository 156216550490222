export function Kiosk() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.625 15.3375V22.875M8.625 22.875H7.125M8.625 22.875H10.125M27.375 22.875V15.3375M27.375 22.875H28.875M27.375 22.875H25.875M10.125 22.875V28.875H25.875V22.875M10.125 22.875H25.875M7.125 12L9 7.125H27L28.875 12V13.5C28.875 14.5355 28.0355 15.375 27 15.375C25.9645 15.375 24.75 14.5355 24.75 13.5C24.75 14.5355 23.5355 15.375 22.5 15.375C21.4645 15.375 20.25 14.5355 20.25 13.5C20.25 14.5355 19.0355 15.375 18 15.375C16.9645 15.375 15.75 14.5355 15.75 13.5C15.75 14.5355 14.5355 15.375 13.5 15.375C12.4645 15.375 11.25 14.5355 11.25 13.5C11.25 14.5355 10.0355 15.375 9 15.375C7.96447 15.375 7.125 14.5355 7.125 13.5V12Z'
        stroke='#081F24'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
