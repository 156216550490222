import moment from "moment";
import { relativeStatus } from "../utils/constants";
import {
  capitalizeStatusText,
  formatMoney,
  handlePrintOrder,
  LSUserData,
} from "../utils/functions";
import { Loader } from "./loader";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PurchaseOrderDocument } from "./purchase-order-document.template";
import { useReactToPrint } from "react-to-print";

export function UnpaidInvoicesTable({
  unpaidInvoices = [],
  selectedInvoice,
  handleDownloadInvoice,
  downloading,
  setSelectedInvoice,
}) {
  const navigate = useNavigate();
  console.log({ unpaidInvoices });
  return (
    <div className='entities'>
      <table class='table'>
        <thead>
          <tr>
            <th scope='col' colSpan={2}>
              Amount Due
            </th>
            <th scope='col' colSpan={1}>
              Invoice Number
            </th>
            <th scope='col' colSpan={1}>
              Bill To
            </th>
            <th scope='col' colSpan={1}>
              Created On
            </th>
            <th scope='col' colSpan={2}>
              Due by
            </th>
            <th scope='col' colSpan={4}></th>
          </tr>
        </thead>
        <tbody>
          {unpaidInvoices.map((invoice, key) => (
            <tr
              data-bs-toggle='offcanvas'
              href='#offcanvasInvoiceView'
              aria-controls='offcanvasInvoiceView'
              key={key}
            >
              <td className='amount' colSpan={2}>
                <span>
                  {invoice?.currency?.symbol}
                  {formatMoney(invoice.amount)}
                </span>
                <span>{invoice?.currency?.iso}</span>
              </td>
              <td colSpan={1}>{invoice.invoiceNumber || "-"}</td>
              <td colSpan={1}>{invoice.outletBusinessName || "-"}</td>
              <td colSpan={1}>
                <span>{moment(invoice.createdAt).format("Do MMM")}</span>
              </td>
              <td colSpan={2}>
                <span>{moment(invoice.dueAt).format("Do MMM")}</span>
                <span
                  className={`custom_status ${relativeStatus(invoice.status)}`}
                >
                  {capitalizeStatusText(invoice.status)}
                </span>
              </td>
              <td
                colSpan={4}
                className={"actions-td"}
                onClick={(e) => e.stopPropagation()}
              >
                <span
                  role='button'
                  className={`action-row-button ${
                    downloading ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (downloading) return;
                    setSelectedInvoice(invoice);
                    handleDownloadInvoice(invoice);
                  }}
                >
                  {downloading && selectedInvoice?._id === invoice?._id ? (
                    <Loader />
                  ) : (
                    "Download Invoice"
                  )}
                </span>
                <span
                  role='button'
                  className='action-row-button'
                  onClick={() =>
                    navigate(`/console/order/${invoice.transactions[0]._id}`)
                  }
                >
                  View Order
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function RecentOrdersTable({ orders = [] }) {
  const {
    retailOutlets: [retailOutlet],
  } = LSUserData();
  const [loading, setLoading] = useState(false);
  const { branches = [] } = retailOutlet;
  const [selectedOrder, setSelectedOrder] = useState({});
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (!selectedOrder?.orderReference) return;
    handlePrint();
    setSelectedOrder({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder]);

  const allBranches = useMemo(() => {
    return [
      ...branches,
      {
        _id: retailOutlet._id,
        outletBusinessName: retailOutlet.outletBusinessName,
        streetName: retailOutlet.formattedAddress,
        contactPhone: retailOutlet.contactPhone || "",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  return (
    <div className='entities'>
      <table class='table'>
        <thead>
          <tr>
            <th scope='col' colSpan={2}>
              Amount
            </th>
            <th scope='col' colSpan={2}>
              Reference
            </th>
            <th scope='col' colSpan={2}>
              Summary
            </th>
            <th scope='col' colSpan={1}>
              Delivering to
            </th>
            <th scope='col' colSpan={2}>
              Created On
            </th>
            <th scope='col' colSpan={4}></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, key) => (
            <tr
              key={key}
              onClick={() => navigate(`/console/order/${order._id}`)}
            >
              <td className='amount' colSpan={2}>
                <span>
                  {order?.currency?.symbol}
                  {formatMoney(order.itemTotal || order.orderTotal || 0)}
                </span>
                <span>{order?.currency?.iso || "-"}</span>
              </td>
              <td colSpan={2}>
                {order.reference || order?.draftOrderNumber || "-"}
              </td>
              <td colSpan={2}>{order?.summary || "-"}</td>
              <td className='amount' colSpan={1}>
                <span>
                  {allBranches.find(
                    (branch) => branch._id === order.retailOutletId
                  )?.outletBusinessName || "-"}
                </span>
              </td>
              <td colSpan={2}>
                <span>{moment(order.createdAt).format("Do MMM")}</span>
                <span
                  className={`custom_status ${relativeStatus(order.status)}`}
                >
                  {capitalizeStatusText(order.status)}
                </span>
              </td>
              <td colSpan={4} className={"actions-td"}>
                <span
                  role='button'
                  className={`action-row-button ${loading ? "disabled" : ""}`}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (loading) return;
                    await handlePrintOrder(order, setLoading, setSelectedOrder);
                  }}
                >
                  {loading ? <Loader /> : "Print Order"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className='tdp_create_orders'>
          <div className='body'>
            <div className='right__ detail'>
              <PurchaseOrderDocument
                downloading={true}
                orderReference={selectedOrder?.orderReference || ""}
                order={selectedOrder?.order || {}}
                branch={
                  allBranches.find(
                    (el) => el._id === selectedOrder?.order?.retailOutletId
                  ) || {}
                }
                totalCalculation={selectedOrder?.totalCalculation || {}}
                currency={selectedOrder?.order?.currency?.symbol || ""}
                reviewOrderData={null}
                showPromotionValue={null}
                currencyCode={selectedOrder?.order?.currency?.iso || ""}
                poOrders={{ items: selectedOrder?.poOrders || [] }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
