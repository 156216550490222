export function CustomSelect({
  placeholder = "Select",
  hasInputIcon = false,
  action,
  items = [],
  open = false,
  setOpen = null,
  emptyText = "No Items Found",
}) {
  return (
    <div className='custom-select custom-input'>
      <div className='input_wrapper' onClick={setOpen}>
        <input className='form-control' placeholder={placeholder} />
        <i className='bi bi-chevron-down'></i>
      </div>
      {open ? (
        <div className='_dropdown'>
          {!items.length ? (
            <li>{emptyText}</li>
          ) : (
            items.map((el) => (
              <li onClick={() => action(el)}>
                <div className=''>
                  <p>{el.title}</p>
                  <p>{el.subtext}</p>
                </div>
              </li>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
}
