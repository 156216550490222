import { useNavigate } from "react-router";
import { SearchModal } from "./modals/search.modal";

export function AuthedHeader() {
  const navigate = useNavigate();

  return (
    <>
      <div className='tdp_authed_header'>
        <div className='row'>
          <div className='col-8'>
            <input
              className='form-control'
              placeholder='Search'
              data-bs-toggle='modal'
              data-bs-target='#searchModal'
            />
          </div>
          <div className='col-2 _flex__ender'>
            <p>
              <img
                src='/images/icons/settings.svg'
                alt='settings-svg'
                className='img-fluid'
              />
            </p>
            <p>
              <img
                src='/images/icons/bell.svg'
                alt='bell-svg'
                className='img-fluid'
              />
            </p>
            <button onClick={() => navigate("/create-order")}>New order</button>
          </div>
        </div>
      </div>
      <SearchModal />
    </>
  );
}
