import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/styles/fonts.css";
import Router from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css"; // skeleton
import "react-datepicker/dist/react-datepicker.css"; // date picker
import "react-multi-carousel/lib/styles.css"; // carousel

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router />
    <ToastContainer
      autoClose={7000}
      hideProgressBar={true}
      closeButton={false}
      position='bottom-right'
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
