import * as Yup from "yup";

export const RegisterScreenOneSchema = Yup.object().shape({
  firstName: Yup.string().required("You must provide your first name"),
  lastName: Yup.string().required("You must provide your last name"),
  email: Yup.string().email().required("You must provide a valid email"),
  contactPosition: Yup.string().required("Provide your contact position"),
  phoneNumber: Yup.number()
    //     .max(14)
    //     .min(14)
    .required("Provide your phone number"),
});
