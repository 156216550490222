export const SHOP = { WEB: "SHOP.WEB" };
export const TD_DOMAIN = "tradedepot";
export const SHOPTOPUP_DOMAIN = "shoptopup";
export const ITEMS_OUT_OF_STOCK = "ITEMS_OUT_OF_STOCK";
export const BELOW_MDV = "BELOW_MDV";
export const INVALID_PRICING_AREA = "INVALID_PRICING_AREA";
export const SALES_LOCATION_NOT_FOUND = "SALES_LOCATION_NOT_FOUND";
export const relativeStatus = (incomingStatus = "") => {
  const status = incomingStatus.toLowerCase();
  const processingStatuses = ["unpaid", "open"];
  const draftStatuses = ["draft"];
  const failedStatuses = ["cancelled", "overdue"];
  const successStatuses = ["paid"];
  if (processingStatuses.includes(status)) return "processing";
  if (draftStatuses.includes(status)) return "draft";
  if (failedStatuses.includes(status)) return "failed";
  if (successStatuses.includes(status)) return "success";
};
export const PRODUCT_PREPARE_ERRORS = [
  {
    tag: ITEMS_OUT_OF_STOCK,
    title: "Items out of stock",
    message:
      "The following items are currently not available, if you proceed, we will create your order but they will not be included",
    type: "oos",
  },
  {
    tag: INVALID_PRICING_AREA,
    title: "Items not available in your area",
    type: "oos",
    message:
      "The following items are currently not available in your location, if you proceed, we will create your order but they will not be included",
  },
  {
    tag: SALES_LOCATION_NOT_FOUND,
    title: "Unavailable Items",
    message:
      "The following items are currently not available, if you proceed, we will create your order but they will not be included",
  },
  {
    tag: BELOW_MDV,
    title: "Below Minimum Delivery Value",
    type: "fixable",
    message:
      "The following items are below delivery value. You can increase the quantity of one or more of the products to include them in your order.",
  },
];

export const businessTypes = [
  { code: "bn", value: "Business Name Registration" },
  { code: "co", value: "Public Limited Companies" },
  { code: "it", value: "Incorporated Trustees" },
];

export const businessStatuses = [
  "Limited Liability Company",
  "Partnership",
  "Sole Trader",
];

export const LS_CART_PRODUCTS = "LS_CART_PRODUCTS";

export const DOCU_SIGN_OUTLETS = [
  "3hT5xqaP5A2rSyQeG",
  "GCaEDJGWrN6X8a3wW",
  "wWi6oWs42xtJYtKt8",
  "dowjBjMehfqu8dTte",
  "uaPR5oMKJwuQx2RtK",
];
