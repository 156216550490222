export function InvoiceSvg() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.4584 14.7917V8.375C22.4584 7.08634 21.4137 6.04167 20.1251 6.04167H7.87508C6.58642 6.04167 5.54175 7.08634 5.54175 8.375V20.625C5.54175 21.9137 6.58642 22.9583 7.87508 22.9583H13.1251M10.2084 10.7083H17.7917M10.2084 15.375H17.7917M17.2084 21.2083L18.9584 22.9583C19.8334 20.3333 22.4584 18.875 22.4584 18.875'
        stroke='#6B797C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
