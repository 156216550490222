import { PhoneOnboarding } from "../../pages/onboarding-old";
import { LSUserData } from "../../utils/functions";
import refreshOutlet from "../../utils/refreshOutlet";

export function AddPhoneNumberDrawer({ afterComplete }) {
  const userData = LSUserData();
  return (
    <>
      <p
        style={{ display: "none" }}
        data-bs-toggle='offcanvas'
        href='#offcanvasphone'
        role='button'
        aria-controls='offcanvasphone'
        id='offcanvas-phone'
      ></p>
      <div
        className='offcanvas offcanvas-end tdp_phone_offcanvas'
        tabindex='-1'
        id='offcanvasphone'
        aria-labelledby='offcanvasphoneLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvas-phoneLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-add-phone-number-drawer'
            ></i>
            <p>Add Phone Number</p>
          </h5>
        </div>
        <div className='offcanvas-body tdp_onboarding'>
          <div className='body'>
            <div className='onboarding_form'>
              <PhoneOnboarding
                userData={userData}
                nextStep={async () => {
                  await refreshOutlet();
                  await afterComplete();
                }}
                subText={
                  "To complete your order creation, add your phone number"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
