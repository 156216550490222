import moment from "moment";
import { formatMoney, LSUserData } from "../utils/functions";
import { TDIcon } from "../assets/td.icon";

export function PurchaseOrderDocument({
  orderReference = "",
  branch,
  poOrders,
  currency,
  reviewOrderData,
  totalCalculation,
  showPromotionValue,
  currencyCode,
  downloading,
  order,
}) {
  const {
    retailOutlets: [retailOutlet],
  } = LSUserData();
  const { country } = retailOutlet;

  return (
    <div className='document__preview'>
      <div className='_title'>
        <h4>Purchase Order</h4>
        <p>
          <TDIcon />
        </p>
      </div>
      <div className='_info-details'>
        <p>Order number</p>
        <p>
          {orderReference ? `${orderReference} -` : ""}{" "}
          {!downloading ? "DRAFT" : ""}
        </p>
      </div>
      <div className='_info-details'>
        <p>Date</p>
        <p>
          {order?.createdAt
            ? moment(order.createdAt).format("MMMM D, YYYY")
            : moment().format("MMMM D, YYYY")}
        </p>
      </div>
      <div className='_order-details'>
        {downloading ? (
          <>
            <div className=''>
              <h4>Ship To</h4>
              <p>{branch?.outletBusinessName || "-"}</p>
              <p className='second'>{branch?.streetName || "-"}</p>
            </div>
            <div className=''>
              <h4>Supplier</h4>
              <p>TradeDepot</p>
              <p className='second'>
                3/4 Adewunmi Industrial Estate Kudirat Abiola Way, Oregun Ikeja,
                Lagos Nigeria
              </p>
            </div>
          </>
        ) : (
          <>
            <div className=''>
              <h4>Company Name</h4>
              <p>{branch?.outletBusinessName || "-"}</p>
            </div>
            <div className=''>
              <h4>Delivery Address</h4>
              <p>{branch?.streetName || branch?.outletBusinessName || "-"}</p>
            </div>
          </>
        )}
      </div>
      <table class='table table-borderless custom_tdp_table'>
        <thead>
          <tr>
            <th scope='col' colSpan={10}>
              Description
            </th>
            <th scope='col'>Qty</th>
            <th scope='col'>Unit Price</th>
            <th scope='col'>Discount</th>
            <th scope='col'>Tax</th>
            <th scope='col'>Amount</th>
          </tr>
        </thead>
        <tbody class='table-group-divider'>
          {poOrders.items.map((el, key) => (
            <tr key={key}>
              <td colSpan={10}>
                {el.name} x{el.count}
              </td>
              <td>{el.count}</td>
              <td>
                {el.currency.symbol}
                {formatMoney(el.price)}
              </td>
              <td>
                {el.currency.symbol} {formatMoney(el?.discount || 0)}
                {/* {el?.promotion
                  ? showPromotionValue(el.promotion, el.currency.symbol)
                  : formatMoney(el.principalPrice - el.price || 0)} */}
              </td>
              <td>{el.currency.symbol} 0</td>
              <td>
                {el.currency.symbol}
                {formatMoney(el.price * el.count)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='total-section'>
        <div className='_section'>
          <p>Subtotal</p>
          <p>
            {currency} {formatMoney(totalCalculation.subtotal)}
          </p>
        </div>
        {totalCalculation?.discount ? (
          <div className='_section'>
            <p>Discounts</p>
            <p>
              {currency} {formatMoney(totalCalculation.discount || 0)}
            </p>
          </div>
        ) : null}
        {totalCalculation?.taxes ? (
          <div className='_section'>
            <p>Taxes</p>
            <p>
              {currency} {formatMoney(totalCalculation.taxes || 0)}
            </p>
          </div>
        ) : null}
        <>
          {country === "GB" ? (
            <>
              <div className='_section'>
                <p>Shipping Fees</p>
                <p>
                  {currency} {formatMoney(totalCalculation?.shippingFees || 0)}
                </p>
              </div>
              <div className='_section'>
                <p>Processing Cost</p>
                <p>
                  {currency} {formatMoney(reviewOrderData?.processingCost || 0)}
                </p>
              </div>
            </>
          ) : null}
        </>
        <div className='_section'>
          <p>Total</p>
          <p>
            {currency}
            {formatMoney(totalCalculation.total)}
          </p>
        </div>
        <div className='_section'>
          <h4>Amount Due</h4>
          <h4>
            {currency} {formatMoney(totalCalculation.amountDue)} {currencyCode}
          </h4>
        </div>
      </div>
      <div className='footer'>
        <p>
          {orderReference} {!downloading ? "- DRAFT" : ""} - {currency}
          {formatMoney(totalCalculation.total)} {currencyCode}
        </p>
      </div>
    </div>
  );
}
