import { ImageWithFallback } from "../../pages/console";
import { formatMoney } from "../../utils/functions";
import { Counter } from "../counter";
import { ProductsStructure } from "../products-structure";

export function CatalogProductDetail({
  product,
  handleAddProduct,
  handleInputProductQuantity,
  handleRemoveProduct,
  cartProducts,
}) {
  return (
    <div className='category-products-container'>
      <div
        className='offcanvas offcanvas-end tdp_offcanvas tdp_catalog_product'
        tabindex='-1'
        id='offcanvasCatalogProduct'
        aria-labelledby='offcanvasCatalogProductLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvas-CatalogProductLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-catalog-product-drawer'
            ></i>
            <p>{product?.name}</p>
          </h5>
        </div>
        <div className='offcanvas-body'>
          <div className='product-container'>
            <ImageWithFallback
              fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
              src={`${process.env.REACT_APP_PRODUCT_URL}/${product?.variantId}.png`}
              alt={product?.description}
              isBigScreen={true}
              key={product?._id}
            />
            <div className='product-card'>
              <h2>{product?.name}</h2>
              <h6>{product?.category}</h6>
              <p className='price'>
                {product?.currency?.symbol}
                {formatMoney(product?.price || 0)}
              </p>
              <p className='code'>{product?.code}</p>
              <div class='accordion' id='accordionExample'>
                <div class='accordion-item'>
                  <h2 class='accordion-header'>
                    <button
                      class='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Description
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    class='accordion-collapse collapse show'
                    data-bs-parent='#accordionExample'
                  >
                    <div class='accordion-body'>
                      {product?.description || "-"}
                    </div>
                  </div>
                </div>
                <div class='accordion-item'>
                  <h2 class='accordion-header'>
                    <button
                      class='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Ingredients
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    class='accordion-collapse collapse'
                    data-bs-parent='#accordionExample'
                  >
                    <div class='accordion-body'>
                      {product?.productName || "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className='quantity-container'>
                <h5>Quantity</h5>
                <Counter
                  handleAddProduct={handleAddProduct}
                  handleInputProductQuantity={handleInputProductQuantity}
                  handleRemoveProduct={handleRemoveProduct}
                  cartProducts={cartProducts}
                  product={product}
                />
              </div>
              {/* <button className='button'>Add to Order</button>
              <button className='button secondary'>Go to order</button> */}
            </div>
          </div>
          <div className='other_products tdp_product_catalog'>
            <h5>Similar Items</h5>
            <ProductsStructure
              products={product?.similarItems || []}
              handleAddProduct={handleAddProduct}
              cartProducts={cartProducts}
              handleInputProductQuantity={handleInputProductQuantity}
              handleRemoveProduct={handleRemoveProduct}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
