/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import {
  errorHandler,
  formatMoney,
  getCountryDetails,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { AccountStatementSvg } from "../assets/account-statement-svg";
import { MessageIcon } from "../assets/message.icon";
import { CalendarIcon } from "../assets/calendarsvg";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Pagination } from "../components/pagination";
import { toast } from "react-toastify";

export function AccountStatement() {
  const [loadingScreen, setLoadingScreen] = useState(true);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [statements, setStatements] = useState([]);
  const [overviewData, setOverviewData] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [pagination, setPagination] = useState({ totalCount: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const {
    apiKey,
    retailOutlets: [retailOutlet],
  } = LSUserData();
  const { country } = retailOutlet;
  const { currency } = getCountryDetails(country);

  const endDate = selectedDates[1];

  useEffect(() => {
    handleFetchStatements();
  }, [endDate, sortBy, currentPage, selectedDates.length, filter]);

  const handleFetchStatements = async () => {
    if (selectedDates.length && !selectedDates?.[1]) return;
    setLoadingScreen(true);
    let url = `${process.env.REACT_APP_AWS_API_URL_V4}/account-statement?batch=${currentPage}&limit=10&type=list`;
    if (selectedDates.length && selectedDates?.[1]) {
      url = url + `&startDate=${moment(selectedDates[0]).unix()}`;
      if (selectedDates[1])
        url = url + `&endDate=${moment(selectedDates[1]).unix()}`;
    }
    if (sortBy) url = url + `&sortBy=${sortBy}`;
    if (filter) url = url + `&paymentType=${filter}`;
    try {
      const {
        data: {
          data: {
            transactions,
            balance,
            totalCredits,
            totalDebits,
            totalCount,
            pagination,
          },
        },
      } = await axios.get(url, { headers: { "x-api-key": apiKey } });
      setStatements(transactions);
      setOverviewData({ totalCredits, totalDebits, balance });
      setPagination({ totalCount, ...pagination });
    } catch (error) {
      console.log("ere", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Failed to fetch statements",
        message,
        "fetch-statements-err"
      );
    } finally {
      setLoadingScreen(false);
    }
  };

  const handleSendStatementToEmail = async () => {
    renderErrorToast(
      "Processing...",
      `Please hold on while we process and end this statement to ${retailOutlet?.email}`,
      "statement-email-send-msg"
    );
    try {
      let url = `${process.env.REACT_APP_AWS_API_URL_V4}/account-statement?batch=${currentPage}&limit=10&type=email`;
      if (selectedDates.length && selectedDates?.[1]) {
        url = url + `&startDate=${moment(selectedDates[0]).unix()}`;
        if (selectedDates[1])
          url = url + `&endDate=${moment(selectedDates[1]).unix()}`;
      }
      await axios.get(url, { headers: { "x-api-key": apiKey } });
      toast.dismiss();
      renderErrorToast(
        "Successful!",
        `Your account statement has been sent to ${retailOutlet?.email}`,
        "statement-email-msg"
      );
    } catch (error) {
      console.log("handing sending statement to email", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error sending account statement",
        message,
        "account-statement-sending-err"
      );
    }
  };

  useEffect(() => {
    scrollBackToTop();
  }, [filter, sortBy, selectedDates]);

  return (
    <div className='tdp_orders tdp_account_statement'>
      <div className='header page-header'>
        <h2>Account Statement</h2>
        <div className='balance-cards'>
          <div
            className={`card ${!filter ? "active" : ""}`}
            onClick={() => setFilter("")}
          >
            <h6>Balance</h6>
            <h2>
              {currency}
              {formatMoney(overviewData?.balance || 0)}
            </h2>
          </div>
          <div
            className={`card ${filter === "credits" ? "active" : ""}`}
            onClick={() => setFilter("credits")}
          >
            <h6>Total Credit</h6>
            <h2>
              {currency}
              {formatMoney(overviewData?.totalCredits || 0)}
            </h2>
          </div>
          <div
            className={`card ${filter === "debits" ? "active" : ""}`}
            onClick={() => setFilter("debits")}
          >
            <h6>Total Debit</h6>
            <h2>
              -{currency}
              {formatMoney(overviewData?.totalDebits || 0)}
            </h2>
          </div>
        </div>
        <div className='action-row'>
          <div className='__filters'>
            <div className='custom-filter-select'>
              <select
                className='form-select'
                onChange={({ target: { value } }) => setSortBy(value)}
              >
                <option selected disabled>
                  Sort by
                </option>
                <option value='asc'>Ascending</option>
                <option value='desc'>Descending</option>
              </select>
            </div>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Select Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) =>
                  setSelectedDates(dates.filter((date) => date !== null))
                }
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
              />
              <CalendarIcon />
            </div>
          </div>
          <div className='buttons'>
            {/* <button>
              Download <DownloadIcon />
            </button> */}
            <button
              disabled={!statements.length}
              onClick={handleSendStatementToEmail}
            >
              Send to Email
              <MessageIcon />
            </button>
          </div>
        </div>
      </div>
      <div className='body page-body'>
        {/* <div className='__input-search'>
          <i className='bi bi-search'></i>
          <input
            className='form-control order-search'
            placeholder='Type to search by reference number or destination'
            onChange={({ target: { value } }) => setSearchText(value)}
          />
        </div> */}
        <div className='orders-list'>
          {loadingScreen ? (
            <Skeleton count={10} />
          ) : !loadingScreen && !statements.length ? (
            <div className='empty__list'>
              <AccountStatementSvg />
              <h3>No statements</h3>
              <h5>
                {selectedDates.length > 1
                  ? "There are no statements matching your filter criteria"
                  : ""}
              </h5>
              <p onClick={() => navigate("/create-order")}>
                Create a new order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen && statements.length ? (
            <div className='entities'>
              <table class='table'>
                <thead>
                  <tr>
                    <th scope='col'>Date</th>
                    <th scope='col'>Reference Number</th>
                    <th scope='col'>Debit</th>
                    <th scope='col'>Credit</th>
                    <th scope='col'>Balance</th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody>
                  {statements.map((el) => (
                    <tr>
                      <td>{moment(el.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{el.invoiceNumber}</td>
                      <td>
                        <span className='debit'>
                          {el.transactionType === "debits"
                            ? `${currency}${formatMoney(el.amount)}`
                            : null}
                        </span>
                      </td>
                      <td>
                        <span className='credit'>
                          {el.transactionType === "credits"
                            ? `${currency}${formatMoney(el.amount)}`
                            : null}
                        </span>
                      </td>
                      <td>
                        <span className='balance'>
                          {currency}
                          {formatMoney(el.amount)}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
          <Pagination
            dataCount={pagination?.totalCount}
            perPage={pagination?.perPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
