/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import { Orders } from "../assets/orders";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  capitalizeStatusText,
  errorHandler,
  formatMoney,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { relativeStatus } from "../utils/constants";
import { InvoiceView } from "../components/invoice-view";
import axios from "axios";
import { Pagination } from "../components/pagination";
import { Loader } from "../components/loader";
import { CalendarIcon } from "../assets/calendarsvg";

export const triggerDownloadInvoice = async (invoice, apiKey) => {
  if (!apiKey)
    return renderErrorToast(
      "Error triggering invoice downloading",
      "You must pass in APIKey",
      "trigger-invoice-download-err"
    );
  const [orderId] = invoice.orderIds;
  const {
    data: {
      data: { invoiceUrl },
    },
  } = await axios.get(
    `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/get-invoice/${orderId}`,
    { headers: { "x-api-key": apiKey } }
  );
  return invoiceUrl;
};

export function Invoices() {
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState("all");
  const [pagination, setPagination] = useState({ totalCount: 0 });
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { apiKey } = LSUserData();
  const location = useLocation();
  const endDate = selectedDates[1];

  useState(() => {
    scrollBackToTop();
    if (location?.state?.filter) setInvoiceStatus(location?.state?.filter);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setSelectedDates([]);
    handleFetchInvoices();
  }, [invoiceStatus]);

  useEffect(() => {
    handleFetchInvoices();
  }, [currentPage, selectedDates.length, endDate]);

  const handleFetchInvoices = async () => {
    if (selectedDates.length && !endDate) return;
    let filters = { batch: currentPage, limit: 10 };
    if (selectedDates.length && !selectedDates?.[1]) return;
    if (selectedDates && selectedDates.length === 2) {
      filters.startDate = moment(selectedDates[0]).unix();
      if (selectedDates[1])
        filters.endDate = moment(moment(selectedDates[1]).endOf("day")).unix();
    }
    const queryString = new URLSearchParams(filters).toString();
    setLoadingScreen(true);
    let url = `${process.env.REACT_APP_AWS_API_URL_V2}/invoices?${queryString}`;
    if (invoiceStatus !== "all") url = url + `&status=${invoiceStatus}`;
    try {
      const {
        data: {
          data: { invoices, pagination, totalCount },
        },
      } = await axios.get(url, {
        headers: { "x-api-key": apiKey },
      });
      setLoadingScreen(false);
      setInvoices(invoices);
      setPagination({ ...pagination, totalCount });
    } catch (error) {
      console.log("fetching invoices", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching invoices",
        message,
        "fetching-invoices-err"
      );
    }
  };

  const handleDownloadInvoice = async (invoice) => {
    try {
      setDownloading(true);
      const [orderId] = invoice.orderIds;
      const {
        data: {
          data: { invoiceUrl },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/get-invoice/${orderId}`,
        { headers: { "x-api-key": apiKey } }
      );
      window.open(invoiceUrl, "_blank");
      setDownloading(false);
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Download Invoice Error!",
        message,
        "err-downloading-invoice"
      );
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className='tdp_orders tdp_invoices'>
      <div className='header page-header'>
        <h2>Invoices</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={`${invoiceStatus === "all" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("all");
                scrollBackToTop();
              }}
            >
              All invoices
            </button>
            <button
              className={`${invoiceStatus === "unpaid" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("unpaid");
                scrollBackToTop();
              }}
            >
              Unpaid
            </button>
            <button
              className={`${invoiceStatus === "paid" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("paid");
                scrollBackToTop();
              }}
            >
              Paid
            </button>
            <button
              className={`${invoiceStatus === "overdue" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("overdue");
                scrollBackToTop();
              }}
            >
              Overdue
            </button>
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
                popperPlacement='bottom-end'
              />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        <div className='orders-list'>
          {loadingScreen ? (
            <Skeleton count={10} />
          ) : !loadingScreen && !invoices.length ? (
            <div className='empty__list'>
              <Orders />
              <h3>
                {selectedDates.length && invoiceStatus === "all"
                  ? "No invoices matching your filter criteria"
                  : invoiceStatus !== "all"
                  ? `No ${invoiceStatus} invoices`
                  : "No Invoices Yet!"}
              </h3>
              <p onClick={() => navigate("/create-order")}>
                Create a new order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : (
            <>
              <div className='entities'>
                <table class='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Amount</th>
                      <th scope='col' colSpan={1}>
                        Invoice Number
                      </th>
                      <th scope='col' colSpan={1}>
                        Bill To
                      </th>
                      <th scope='col' colSpan={1}>
                        Created On
                      </th>
                      <th scope='col' colSpan={1}>
                        Due by
                      </th>
                      <th scope='col' colSpan={4}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr
                        data-bs-toggle='offcanvas'
                        href='#offcanvasInvoiceView'
                        aria-controls='offcanvasInvoiceView'
                        onClick={() => setInvoiceDetails(invoice)}
                      >
                        <td className='amount'>
                          <p>
                            <span>
                              {invoice?.currency?.symbol}
                              {formatMoney(invoice.amount || 0)}
                            </span>
                            <span>{invoice?.currency?.iso || "-"}</span>
                          </p>
                        </td>
                        <td>{invoice.invoiceNumber || "-"}</td>
                        <td>{invoice.outletBusinessName || "-"}</td>
                        <td>
                          <span>
                            {moment(invoice.createdAt).format("Do MMM")}
                          </span>
                        </td>
                        <td>
                          <span>{moment(invoice.dueAt).format("Do MMM")}</span>
                          <span
                            className={`custom_status ${relativeStatus(
                              invoice.status
                            )}`}
                          >
                            {capitalizeStatusText(invoice.status)}
                          </span>
                        </td>
                        <td colSpan={4} className={"actions-td"}>
                          <span
                            role='button'
                            className='action-row-button'
                            onClick={(e) => {
                              if (downloading) return;
                              handleDownloadInvoice(invoice);
                            }}
                          >
                            {downloading &&
                            invoiceDetails?._id === invoice?._id ? (
                              <Loader />
                            ) : (
                              "Download Invoice"
                            )}
                          </span>
                          <span
                            role='button'
                            className='action-row-button'
                            onClick={() =>
                              navigate(
                                `/console/order/${invoice.transactions[0]._id}`
                              )
                            }
                          >
                            View Order
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
      <InvoiceView
        invoice={invoiceDetails}
        handleDownloadInvoice={(invoice) => handleDownloadInvoice(invoice)}
        downloading={downloading}
        setDownloading={setDownloading}
      />
    </div>
  );
}
