export function Counter({
  cartProducts = [],
  product = {},
  handleAddProduct,
  handleRemoveProduct,
  handleInputProductQuantity,
}) {
  return (
    <div className='custom_counter' onClick={(e) => e.stopPropagation()}>
      <div className='plus_minus_controller'>
        <span onClick={() => handleRemoveProduct(product?._id)}>
          <i className='bi bi-dash-lg'></i>
        </span>
        <input
          value={
            cartProducts.find((cartProduct) => cartProduct._id === product?._id)
              ?.count || 0
          }
          onChange={({ target: { value } }) =>
            handleInputProductQuantity(product?._id, value)
          }
        />
        <span onClick={() => handleAddProduct(product?._id)}>
          <i className='bi bi-plus-lg'></i>
        </span>
      </div>
    </div>
  );
}
