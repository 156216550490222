/* eslint-disable no-unreachable */
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { CalendarIcon } from "../assets/calendarsvg";
import { toast } from "react-toastify";
import { phone } from "phone";
import {
  errorHandler,
  getCountryDetails,
  LSUserData,
  renderErrorToast,
  updateLSOutlet,
  validateEmail,
} from "../utils/functions";
import { TD_DOMAIN } from "../utils/constants";
import useTimer from "../hooks/useTimer";
import moment from "moment";
import { DeleteSvg } from "../assets/delete";
import { EditSvg } from "../assets/edit";

export function Onboarding() {
  const navigate = useNavigate();
  const userData = LSUserData();
  const stepKeys = {
    PHONE_NUMBER_VERIFICATION: "phone-number-verification",
    VERIFY_IDENTITY: "verify-identity",
    ADD_TEAM_MEMBERS: "add-team-members",
  };

  console.log({ userData });

  const getStep = () => {
    // return stepKeys.VERIFY_IDENTITY;
    // return stepKeys.BUSINESS_INFORMATION; //step 3
    if (!userData?.retailOutlets[0]?.contactPhone) {
      return stepKeys.PHONE_NUMBER_VERIFICATION;
    }
    if (!userData?.retailOutlets[0]?.kyc?.idVerified) {
      return stepKeys.VERIFY_IDENTITY;
    }
    return stepKeys.ADD_TEAM_MEMBERS;
  };

  const [stepKey, setStepKey] = useState(getStep());

  const openSuccessModal = () => {
    toast(<SuccessModal navigate={navigate} />, {
      autoClose: 100000000,
      toastId: "success",
      position: "top-right",
    });
  };

  return (
    <div className='tdp_onboarding'>
      <div className='header'>
        <div className='cancel__'>
          <p onClick={() => navigate("/console")}>
            <i className='bi bi-x-lg'></i>
          </p>
          <h4>Complete Onboarding</h4>
        </div>
        <div className='action-buttons'>
          <button onClick={() => navigate("/console")}>Finish later</button>
          <button
            onClick={() => {
              const currentStepIndex = Object.values(stepKeys).findIndex(
                (el) => el === stepKey
              );
              const nextStep = Object.values(stepKeys)[currentStepIndex + 1];
              if (!nextStep) return navigate("/console");
              setStepKey(nextStep);
            }}
            className='_continue'
          >
            Next
          </button>
        </div>
      </div>
      <div className='body container'>
        <div className='row'>
          <div
            className={`${
              stepKey === stepKeys.ADD_TEAM_MEMBERS ? "col-2" : "col-4"
            }`}
          />
          <div
            className={`${
              stepKey === stepKeys.ADD_TEAM_MEMBERS ? "col-8" : "col-4"
            } onboarding_form`}
          >
            {stepKey === stepKeys.PHONE_NUMBER_VERIFICATION ? (
              <PhoneOnboarding
                nextStep={() => setStepKey(stepKeys.VERIFY_IDENTITY)}
                userData={userData}
              />
            ) : stepKey === stepKeys.VERIFY_IDENTITY ? (
              <VerifyIdentity
                nextStep={() => setStepKey(stepKeys.ADD_TEAM_MEMBERS)}
                userData={userData}
              />
            ) : stepKey === stepKeys.ADD_TEAM_MEMBERS ? (
              <TeamMembers
                openSuccessModal={openSuccessModal}
                userData={userData}
              />
            ) : null}
          </div>
          <div
            className={`${
              stepKey === stepKeys.ADD_TEAM_MEMBERS ? "col-2" : "col-4"
            }`}
          />
        </div>
      </div>
    </div>
  );
}

export function PhoneOnboarding({ nextStep, userData, subText = null }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [seconds, setStartCountdown] = useTimer();
  const [resendingToken, setResendingToken] = useState(false);
  const [retailOutlet] = userData.retailOutlets;
  const { country } = retailOutlet;
  const { countryCode } = getCountryDetails(country);

  const handleValidateNumber = () => {
    setLoading(true);
    const { isValid: numberIsValid, phoneNumber: formattedPhoneNumber } = phone(
      phoneNumber,
      {
        country,
      }
    );
    console.log({ numberIsValid });
    if (!numberIsValid) {
      setLoading(false);
      return renderErrorToast(
        "Invalid Phone Number", // token
        "Please, check your phone number and provide it in a valid format", //message
        "invalid-phone" // toast-id
      );
    }
    setPhoneNumber(formattedPhoneNumber);
    handleVerifyPhone({ formattedPhone: formattedPhoneNumber });
  };

  const handleVerifyPhone = async ({ formattedPhone, isResending = false }) => {
    if (isResending) setResendingToken(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        { mode: "whatsapp", phoneNumber: formattedPhone, domain: TD_DOMAIN }
      );
      if (isResending) {
        setToken("");
        renderErrorToast(
          "Token Sent!",
          "Token has been resent to your whatsapp",
          "resend-token-whatsapp"
        );
        setResendingToken(false);
      }
      setLoading(false);
      setStartCountdown(true);
      return setStep(2);
    } catch (error) {
      console.log("sending phone token error", error);
      const { message } = errorHandler(error);
      renderErrorToast("Error sending token!", message, "err-send-phone-token");
      setLoading(false);
    }
  };

  const handleVerifyToken = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/verify-totp`,
        { phoneNumber, token }
      );
      updateOutletPhoneNumber();
    } catch (error) {
      console.log("verifying phone token error", error);
      const { message } = errorHandler(error);
      renderErrorToast("Verify Token Error!", message, "err-verify-token");
      setLoading(false);
    }
  };

  const updateOutletPhoneNumber = async () => {
    try {
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/update-outlet-phone`,
        { phoneNumber },
        {
          headers: {
            "x-api-key": userData?.apiKey,
          },
        }
      );
      updateLSOutlet(data);
      renderErrorToast(
        "Success!",
        "Phone number has been updated",
        "success-phone-number"
      );
      nextStep(); // completed update, move to the next screen!
    } catch (error) {
      console.log("updating outlet phone number", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error updating outlet phone!",
        message,
        "error-outlet-phone"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (step === 1 && phoneNumber) {
          handleValidateNumber();
        }
        if (step === 2 && token) {
          handleVerifyToken();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, token]);

  return (
    <div id='onboarding__phone'>
      {step === 1 ? (
        <div className='phone_input_form'>
          <h3>Add a phone number</h3>
          {subText ? <h6>{subText}</h6> : null}
          <input
            className='form-control'
            placeholder={`+${countryCode} 000 000 000`}
            type='number'
            min={0}
            onChange={({ target: { value } }) => setPhoneNumber(value)}
          />
          <button
            disabled={loading || !phoneNumber.length}
            onClick={handleValidateNumber}
          >
            {loading ? (
              <div
                class='spinner-border text-success app-spinner'
                role='status'
              >
                <span class='sr-only'></span>
              </div>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      ) : step === 2 ? (
        <div className='confirm__phone'>
          <h3>Confirm phone number</h3>
          <h5>
            Enter the verification code we sent to the number you provided
          </h5>
          <div className='v-input__'>
            <input
              placeholder='Enter verification code'
              className='form-control'
              onChange={({ target: { value } }) => setToken(value)}
              disabled={resendingToken}
              maxLength={6}
              value={token}
            />
            <span
              onClick={() => {
                if (seconds) return;
                return handleVerifyPhone({
                  formattedPhone: phoneNumber,
                  isResending: true,
                });
              }}
            >
              {resendingToken ? (
                <div
                  class='spinner-border text-success app-spinner input-spinner'
                  role='status'
                >
                  <span class='sr-only'></span>
                </div>
              ) : !seconds ? (
                "Resend"
              ) : (
                `Resend in ${seconds}s`
              )}
            </span>
          </div>
          <button
            onClick={handleVerifyToken}
            disabled={!token.length || loading}
          >
            {loading ? (
              <div
                class='spinner-border text-success app-spinner'
                role='status'
              >
                <span class='sr-only'></span>
              </div>
            ) : (
              "Continue"
            )}
          </button>
          <p>
            Still haven’t received a code?{" "}
            <span
              onClick={() => {
                setToken("");
                setStep(1);
              }}
            >
              try a different phone number.
            </span>
          </p>
        </div>
      ) : null}
    </div>
  );
}

function VerifyIdentity({ nextStep, userData }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { firstName, lastName, email, retailOutlets } = userData;
  const [kycBody, setKycBody] = useState({ dob: "", bvn: "" });
  const [kycStatus, setKycStatus] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const [retailOutlet] = retailOutlets;
  const { country } = retailOutlet;
  const navigate = useNavigate();

  useEffect(() => {
    getKYCStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKYCStatus = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V2}/current-job`,
        {
          headers: {
            "x-api-key": userData?.apiKey,
          },
        }
      );
      console.log({ data });
      if (data?.bvnJob?.length) setShowStatus(true);
      setKycStatus(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const initializeUKKYC = async () => {
    try {
      const {
        data: {
          data: { verificationUrl },
        },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/create-verification-session`,
        { redirectUrl: `${process.env.REACT_APP_PROCUREMENT_URL}/onboarding` },
        {
          headers: {
            "x-api-key": userData?.apiKey,
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      window.location.replace(verificationUrl);
    } catch (error) {
      console.log({ error });
      const { message } = errorHandler(error);
      setLoading(false);
      renderErrorToast("KYC Failed", message, "kyc-verification-uk");
    }
  };

  const handleStartVerification = () => {
    setLoading(true);
    if (country === "NG") processNGVerification();
    if (country === "GB") initializeUKKYC();
  };

  const configureSmileIdentityWebIntegration = (token, jobId) => {
    window.SmileIdentity({
      token,
      product: "biometric_kyc",
      id_selection: { NG: ["BVN"] },
      consent_required: { NG: ["BVN"] },
      callback_url: `${process.env.REACT_APP_AWS_API_URL_V2}/smile-webhook`,
      environment: process.env.REACT_APP_CUSTOM_ENVIRONMENT,
      partner_details: {
        partner_id: process.env.REACT_APP_SMILE_PARTNER_ID,
        name: process.env.REACT_APP_SMILE_PARTNER_NAME,
        logo_url: `https://tradedepot.co/images/logo-alt.svg`,
        policy_url: `https://shoptopup.com/privacy`,
        theme_color: "#FF8D06",
      },
      file_name: "Archive.zip",
      model_parameters: {},
      signature: "--",
      source_sdk_version: "1.0",
      source_sdk: "rest_api",
      timestamp: "2025-03-07T13:23:24.591Z",
      partner_params:
        process.env.REACT_APP_CUSTOM_ENVIRONMENT === "sandbox"
          ? {
              Photo: "/9j/4AAQSkZJRgALISOad5n1/OolQYz607YK/2Q==",
              // Photo: "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
            }
          : null,
      onSuccess: async (res) => {
        await validateVerificationJob(jobId);
        setLoading(false);
        renderErrorToast(
          "KYC Success!",
          "Your details have been captured and are being reviewed.",
          "kyc-success-ng"
        );
        getKYCStatus();
      },
      onClose: () => {
        setLoading(false);
      },
      onError: (error) => {
        const { message } = errorHandler(error);
        renderErrorToast("KYC Verification Failed", message, "kyc-failed-ng");
        setLoading(false);
      },
    });
  };

  const processNGVerification = async () => {
    let verifyBody = {};
    if (!kycStatus?.runEnhanced) {
      verifyBody = {
        fields: {
          firstName,
          lastName,
          dob: moment(kycBody.dob).format("DD-MM-YYYY"),
        },
        job_type: "bvn",
        idNumber: kycBody.bvn,
      };
    } else {
      verifyBody = { job_type: "bvn" };
    }
    console.log({ verifyBody });
    try {
      const {
        data: {
          data: { token, job_id },
        },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/initiate-job`,
        verifyBody,
        {
          headers: {
            "x-api-key": userData.apiKey,
          },
        }
      );
      console.log({ job_id });
      configureSmileIdentityWebIntegration(token, job_id);
    } catch (error) {
      console.log("errpr", error);
      setLoading(false);
      const { message } = errorHandler(error);
      renderErrorToast("Error processing KYC", message, "processing-kyc-err");
    }
  };

  const validateVerificationJob = async (jobId) => {
    if (!jobId) return;
    try {
      await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/validate-job`,
        { job_id: jobId },
        { headers: { "x-api-key": userData.apiKey } }
      );
    } catch (error) {
      console.log({ error }, "verification job errr");
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error validating job",
        message,
        "validate-verification-job"
      );
    }
  };

  return (
    <div className='verify_identity_form'>
      {kycStatus?.bvnJob?.length && showStatus ? (
        <div className='kyc_status _section'>
          <div className='_block' onClick={() => setStep(2)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              class='bi bi-person-check'
              viewBox='0 0 16 16'
            >
              <path d='M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4' />
              <path d='M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z' />
            </svg>
            <div className=''>
              <h4>KYC Verification Status</h4>
              {kycStatus?.bvnJob?.[0]?.status === "failed" ? (
                <h6>{kycStatus?.bvnJob?.[0]?.resultText}</h6>
              ) : kycStatus?.bvnJob?.[0]?.status === "running" ? (
                <h6>Your details are being verified.</h6>
              ) : (
                <h6>Successfully completed KYC Verification</h6>
              )}
              <p className={`status ${kycStatus?.bvnJob?.[0]?.status}`}>
                {kycStatus?.bvnJob?.[0]?.status}
              </p>
            </div>
          </div>
          {kycStatus?.bvnJob?.[0]?.status === "failed" ? (
            <button
              onClick={() => {
                setShowStatus(false);
                setStep(2);
              }}
            >
              Verify Again
            </button>
          ) : null}
        </div>
      ) : (
        <>
          {step === 1 ? (
            <div className='__first-step'>
              <h3>Verify your identity</h3>
              <h5>
                We need you to provide some additional information to verify
                your identity.
              </h5>
              <button
                disabled={loading}
                onClick={
                  country === "GB"
                    ? () => handleStartVerification()
                    : () => setStep(2)
                }
              >
                {loading ? (
                  <div
                    class='spinner-border text-success app-spinner'
                    role='status'
                  >
                    <span class='sr-only'></span>
                  </div>
                ) : (
                  "Start Verification"
                )}
              </button>
              <button onClick={() => navigate("/console")}>
                Back to Business details
              </button>
            </div>
          ) : (
            <div className='__second-step'>
              <h3>Personal Information</h3>
              <div className='__flex'>
                <input
                  className='form-control'
                  placeholder='John'
                  value={firstName}
                  disabled
                />
                <input
                  className='form-control'
                  placeholder='Doe'
                  value={lastName}
                  disabled
                />
              </div>
              <input
                className='form-control'
                placeholder='doe@gmail.com'
                value={email}
                disabled
              />
              {!kycStatus?.runEnhanced ? (
                <>
                  <div className='date-picker-container-custom'>
                    <DatePicker
                      onChange={(date) =>
                        setKycBody({ ...kycBody, dob: moment(date).format() })
                      }
                      placeholderText='Date of birth (dd/mm/yy)'
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      value={
                        kycBody.dob
                          ? moment(kycBody.dob).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                    <CalendarIcon />
                  </div>
                  <input
                    className='form-control'
                    placeholder='BVN Number'
                    type='number'
                    onChange={({ target: { value } }) =>
                      setKycBody({ ...kycBody, bvn: value })
                    }
                    value={kycBody?.bvn}
                  />
                </>
              ) : null}
              <button
                disabled={
                  loading ||
                  (!kycStatus?.runEnhanced &&
                    !(kycBody?.bvn && kycBody?.dob)) ||
                  (!kycStatus?.runEnhanced &&
                    (kycBody?.bvn?.length !== 11 ||
                      typeof Number(kycBody?.bvn) !== "number"))
                }
                onClick={handleStartVerification}
                id='verify-with-smile-id'
              >
                {loading ? (
                  <div
                    class='spinner-border text-success app-spinner'
                    role='status'
                  >
                    <span class='sr-only'></span>
                  </div>
                ) : (
                  "Start Verification"
                )}
              </button>
              {/* {isAParentOutlet() ? (
                <div className='admin_info'>
                  <p>
                    <InfoSvg />
                  </p>
                  <h4>
                    You are the default admin. You can add one or more team
                    members.
                  </h4>
                </div>
              ) : null} */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function TeamMembers({ openSuccessModal, userData }) {
  const [members, setMembers] = useState([]);
  const [showBranches, setShowBranches] = useState({ show: false, id: null });
  const [retailOutlet] = userData.retailOutlets;
  const retailOutletBranches = retailOutlet.branches;
  const [loading, setLoading] = useState(false);
  const newMemberProps = {
    firstName: "",
    lastName: "",
    email: "",
    canLogin: false,
    branchOutletId: "",
    edit: true,
    saved: false, // faux key
  };

  useEffect(() => {
    setMembers([newMemberProps]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewUserBtnIsDisabled = () => {
    if (!members.length) return true;
    const lastMember = members[members.length - 1];
    if (
      !lastMember.firstName ||
      !lastMember.lastName ||
      !lastMember.branchOutletId ||
      !lastMember.email
    ) {
      return true; // button is disabled
    }
  };

  const buttonIsDisabled = () => {
    const unFinishedMemberFilling = members.filter(
      (el) =>
        !el.firstName ||
        !el.lastName ||
        !el.email ||
        !el.branchOutletId ||
        !el.saved
    );
    return !!unFinishedMemberFilling.length && addNewUserBtnIsDisabled();
  };

  const addMemberButtonIsDisabled = useMemo(() => {
    const unFinishedMemberFilling = members.filter(
      (el) =>
        !el.firstName ||
        !el.lastName ||
        !el.email ||
        !el.branchOutletId ||
        !el.saved
    );
    const memberIsInEdit = members.find(
      (el) => el.editFirstName || el.editLastName
    );
    return unFinishedMemberFilling.length || memberIsInEdit;
  }, [members]);

  const handleChangeInputValue = (key, index, value) => {
    members[index][key] = value;
    setMembers([...members]);
  };

  const handleCreateBranch = async () => {
    setLoading(true);
    let contacts = [];
    members.forEach((member, key) => {
      const isEmailValid = validateEmail(member.email);
      if (!isEmailValid) {
        setLoading(false);
        return renderErrorToast(
          "Invalid Email",
          `Email format ${key + 1} is invalid`,
          "invalid-email-address-err"
        );
      }
      contacts.push({ ...member, canLogin: true });
    });
    if (!contacts.length) return setLoading(false);
    contacts = contacts.map((el) => {
      delete el.saved;
      delete el.edit;
      return el;
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/retail/contacts`,
        { contacts },
        { headers: { "x-api-key": userData?.apiKey } }
      );
      setMembers([newMemberProps]);
      renderErrorToast(
        "Success!",
        "Contacts have been added successfully!",
        "success-team-members"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("creating contact", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error creating contacts!",
        message,
        "error-creating-contact"
      );
    }
  };

  const processEditMember = () => {
    const memberInEditIndex = members.findIndex(
      (el) => el.editFirstName || el.editLastName
    );
    if (memberInEditIndex > -1) {
      const member = members[memberInEditIndex];
      const obj = {
        firstName: member.editFirstName,
        lastName: member.editLastName,
        email: member.editEmail,
        branchOutletId: member.editBranchOutletId,
        saved: true,
        edit: false,
      };
      members[memberInEditIndex] = obj;
      return setMembers([...members, newMemberProps]);
    }
  };

  const addNewUser = (index) => {
    processEditMember();
    const allMembers = members.map((el) => ({
      ...el,
      edit: false,
      saved: true,
    }));
    setMembers([...allMembers, newMemberProps]);
  };

  const fakeSaveMember = () => {
    processEditMember();
    const allMembers = members.map((el) => ({
      ...el,
      edit: false,
      saved: true,
    }));
    setMembers([...allMembers]);
  };

  const handleRemoveTeamMember = (key) => {
    members.splice(key, 1);
    setMembers([...members]);
  };

  const handleCancelTeamMember = (key) => {
    const member = members[key];
    if (
      !member.email ||
      !member.firstName ||
      !member.lastName ||
      !member.branchOutletId ||
      !member.saved
    ) {
      handleRemoveTeamMember(key);
    }

    const memberInEditIndex = members.findIndex(
      (el) => el.editFirstName || el.editLastName
    );
    if (memberInEditIndex > -1) {
      const member = members[memberInEditIndex];
      const obj = {
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        branchOutletId: member.branchOutletId,
        saved: true,
        edit: false,
      };
      members[memberInEditIndex] = obj;
      return setMembers([...members]);
    }
  };

  const selectTeamMemberForEdit = (key) => {
    const unFinishedMemberFilling = members.filter(
      (el) =>
        !el.firstName ||
        !el.lastName ||
        !el.email ||
        !el.branchOutletId ||
        !el.saved
    );
    if (unFinishedMemberFilling.length)
      return renderErrorToast(
        "Cannot edit member",
        "You have an uncompleted form",
        "edit-error-1"
      );
    const member = members[key];
    const allMembers = members.map((el) =>
      el.email === member.email
        ? {
            ...el,
            edit: true,
            editFirstName: el.firstName,
            editLastName: el.lastName,
            editEmail: el.email,
            editBranchOutletId: el.branchOutletId,
          }
        : { ...el, edit: false }
    );
    setMembers([...allMembers]);
  };

  return (
    <div className='team_members'>
      <h3>Add team members</h3>
      {members.map((member, key) => {
        return (
          <div
            className={`member__row ${member.edit === true ? "open" : ""}`}
            key={key}
          >
            {member.edit ? (
              <>
                <div className='team_row'>
                  <input
                    className='form-control'
                    placeholder='First Name'
                    // value={member.firstName}
                    value={
                      member.edit && member.saved
                        ? member.editFirstName
                        : member.firstName
                    }
                    // onChange={({ target: { value } }) =>
                    //   handleChangeInputValue("firstName", key, value)
                    // }
                    onChange={({ target: { value } }) => {
                      member.edit && member.saved
                        ? handleChangeInputValue("editFirstName", key, value)
                        : handleChangeInputValue("firstName", key, value);
                    }}
                  />
                  <input
                    className='form-control'
                    placeholder='Last Name'
                    value={
                      member.edit && member.saved
                        ? member.editLastName
                        : member.lastName
                    }
                    onChange={({ target: { value } }) => {
                      member.edit && member.saved
                        ? handleChangeInputValue("editLastName", key, value)
                        : handleChangeInputValue("lastName", key, value);
                    }}
                  />
                  <input
                    className='form-control'
                    placeholder='Email address'
                    value={
                      member.edit && member.saved
                        ? member.editEmail
                        : member.email
                    }
                    // onChange={({ target: { value } }) =>
                    //   handleChangeInputValue("email", key, value)
                    // }
                    onChange={({ target: { value } }) => {
                      member.edit && member.saved
                        ? handleChangeInputValue("editEmail", key, value)
                        : handleChangeInputValue("email", key, value);
                    }}
                  />
                  <div className='custom-select'>
                    <div
                      onClick={() => {
                        if (showBranches.show)
                          setShowBranches({ show: false, id: null });
                        else setShowBranches({ show: true, id: key });
                      }}
                    >
                      <select className='form-select' disabled />
                      {(
                        member.edit && member.saved
                          ? member?.editBranchOutletId
                          : member?.branchOutletId
                      ) ? (
                        <span className='filled-value'>
                          {
                            retailOutletBranches.find(
                              (el) =>
                                el._id ===
                                (member.edit && member.saved
                                  ? member?.editBranchOutletId
                                  : member?.branchOutletId)
                            ).outletBusinessName
                          }
                        </span>
                      ) : (
                        <span>Select Location</span>
                      )}
                    </div>
                    {showBranches.show && key === showBranches.id ? (
                      <div className='_dropdown'>
                        {(retailOutletBranches || []).length ? (
                          retailOutletBranches.map((el) => (
                            <div
                              className={`_row ${
                                el._id ===
                                (member.edit && member.saved
                                  ? member?.editBranchOutletId
                                  : member?.branchOutletId)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleChangeInputValue(
                                  member.edit && member.saved
                                    ? "editBranchOutletId"
                                    : "branchOutletId",
                                  key,
                                  el._id
                                );
                                setShowBranches({ show: false, id: null });
                              }}
                            >
                              <h4>{el.outletBusinessName}</h4>
                              <p>{el.streetName}</p>
                            </div>
                          ))
                        ) : (
                          <div className='_row'>
                            <h4>No Branches</h4>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='_action-buttons'>
                  {key + 1 === 1 ? null : (
                    <button
                      className='cancel-btn'
                      onClick={() => handleCancelTeamMember(key)}
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    className='save-add-btn'
                    onClick={() => {
                      addNewUser();
                    }}
                    disabled={buttonIsDisabled()}
                  >
                    Save and Add new
                  </button>
                  <button
                    className='save-btn'
                    onClick={fakeSaveMember}
                    disabled={buttonIsDisabled()}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <tr className='team_row_list'>
                <td>{member.firstName}</td>
                <td>{member.lastName}</td>
                <td>{member.email}</td>
                <td>
                  {
                    retailOutletBranches.find(
                      (el) => el._id === member.branchOutletId
                    ).outletBusinessName
                  }
                </td>
                <td className='_actions'>
                  <span onClick={() => handleRemoveTeamMember(key)}>
                    <DeleteSvg />
                  </span>
                  <span onClick={() => selectTeamMemberForEdit(key)}>
                    <EditSvg />
                  </span>
                </td>
              </tr>
            )}
          </div>
        );
      })}
      <button
        className={`user__add ${addNewUserBtnIsDisabled() ? "disabled" : ""}`}
        onClick={addNewUser}
        disabled={addNewUserBtnIsDisabled()}
      >
        <i className='bi bi-plus-lg'></i>Add User
      </button>
      <button
        disabled={addMemberButtonIsDisabled || loading}
        onClick={handleCreateBranch}
      >
        {loading ? (
          <div class='spinner-border text-success app-spinner' role='status'>
            <span class='sr-only'></span>
          </div>
        ) : (
          "Add members"
        )}
      </button>
    </div>
  );
}

function SuccessModal({ navigate }) {
  return (
    <>
      <div className='tdp_modal'>
        <>
          <h2>
            <span>Nice!</span> your setup is complete
          </h2>
          <h5>
            You’ve completed your onboarding, you can now start creating orders.
          </h5>
          <div className='action_'>
            <button
              onClick={() => {
                navigate("/console");
                toast.dismiss();
              }}
            >
              Back to Home
            </button>
          </div>
        </>
      </div>
    </>
  );
}
